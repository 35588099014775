import 'graphiql/graphiql.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './App.scss';
import {useOktaAuth} from '@okta/okta-react';
import React, {useEffect, useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {ApolloClient, ApolloProvider} from "@apollo/client";
import {InMemoryCache} from "apollo-cache-inmemory";
import GraphiQL from 'graphiql';
import {createGraphiQLFetcher} from '@graphiql/toolkit';
import {Switch, Route, Redirect, Link, useLocation, useHistory} from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListQueries from "./component/ListQueries/ListQueries";
import {encodeUrl, decodeUrl} from './parseQuery';
import {DEFAULT_QUERY} from './gql_bodies';
import Loader from 'react-loader-spinner';
import Alert from '@material-ui/lab/Alert';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const pushQuery = (currentQuery, currentVariables, pathname, history) => {
  const newQuery = {
    query: currentQuery || '',
    variables: currentVariables || ''
  }
  const encodedUrl = encodeUrl(newQuery)
  history.push({
    pathname,
    search: `?query=${encodedUrl}`
  })
}

const maxQueryParamLength = 1024;
let query_initialized = false;

const App = () => {
  const {authState, oktaAuth} = useOktaAuth();
  const [accessToken, setAccessToken] = useState(authState?.accessToken?.accessToken);
  const [actionMessage, setActionMessage] = useState('')
  const [displayActionMessage, setDisplayActionMessage] = useState(false);
  const [urlCopied, setUrlCopied] = useState(false);
  const [currentQuery, setCurrentQuery] = useState('')
  const [currentVariables, setCurrentVariables] = useState('')
  const [tokenExpiration, setTokenExpiration] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const location = useLocation();
  const query = useQuery();
  const queryParamLength = query.toString().length;
  const history = useHistory();

  const [user, setUser] = useState({})
  useEffect(() => {
    if (authState && authState.isAuthenticated) oktaAuth.getUser().then(user => setUser(user))
    else setUser(null);
  }, [authState, oktaAuth])

  const setAndDisplayActionMessage = (actionMessage) => {
    setActionMessage(actionMessage);
    setDisplayActionMessage(true);
  }

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setAccessToken(null)
    } else {
      setAccessToken(authState?.accessToken?.accessToken)
      if (authState?.accessToken) {
        setTokenExpiration(new Date(authState.accessToken.expiresAt * 1000).toString());
      }
    }
  }, [authState, oktaAuth])

  useEffect(() => {
    if (location.pathname === '/explore') {
      const urlQuery = query.get('query')
      if (urlQuery) {
        // If there is a query in the URI, use it...
        const newQuery = decodeUrl(urlQuery)

        // If the query or the variables in the URI is different, set it.
        if (newQuery.query !== currentQuery) {
          setCurrentQuery(newQuery.query);
        }
        if (newQuery.variables !== currentVariables) {
          setCurrentVariables(newQuery.variables);
        }
      } else if (!query_initialized) {
        // No query in the URI, and we haven't set it...ever.  Pull in the default, but only once.
        setCurrentQuery(DEFAULT_QUERY.query);
        setCurrentVariables(DEFAULT_QUERY.variables);
        query_initialized = true;
      } else {
        // Just push the current query into our history.
        pushQuery(currentQuery, currentVariables, location.pathname, history)
      }
    }
  }, [location])

  useEffect(() => {
    if (currentQuery || currentVariables) {
      pushQuery(currentQuery, currentVariables, location.pathname, history)
    }
  }, [currentQuery, currentVariables])

  const handleSelectedQuery = (newValue) => {
    setCurrentQuery(newValue.query || '')

    let variables = JSON.parse(newValue.variables)
    if (variables?.input.email && user !== null) {
      variables.input.email = user.email
      setCurrentVariables(JSON.stringify(variables) || '')
    } else {
      setCurrentVariables(newValue.variables || '')
    }
  };

  const handleRefreshToken = () => {
    setIsRefreshing(true);
    oktaAuth.tokenManager.renew("accessToken").then(() => {
      // Dont need to call setAccessToken as authState/oktaAuth is updated
      setAndDisplayActionMessage("Token refreshed")
      setIsRefreshing(false);
    }).catch((error) => {
      console.error("Error refreshing token, closing okta session to force login.")
      console.error(error)
      oktaAuth.closeSession().finally(() => {
        setIsRefreshing(false);
        window.location.reload()
      })
    })
  }

  const CIS_GRAPH_ENDPOINT = process.env.REACT_APP_URI

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: CIS_GRAPH_ENDPOINT,
    headers: {
      Authorization: accessToken ? `${accessToken}` : '',
    }
  });

  if (accessToken) {
    const fetcher = createGraphiQLFetcher({
      url: CIS_GRAPH_ENDPOINT
    });
    return (
      <ApolloProvider client={client}>
        <div className="main-grid-container">
          <div className="header">
            <h1 className="display-2 text-color-primary-light">
              <a href="/"><i className="g72-swoosh"></i> CIS Graph API</a>
            </h1>
            <Tabs value={location.pathname} aria-label="Navigation">
              <Tab to={'/about'} component={Link} label={'About'} value={'/about'}/>
              <Tab to={'/explore'} component={Link} label={'Explore API'} value={'/explore'}/>
              <Tab to={'/token'} component={Link} label={'Authorization Token'} value={'/token'}/>
            </Tabs>
          </div>
          <div className="main">
            <Switch>
              <Route exact path="/">
                <Redirect to="/about"/>
              </Route>
              <Route exact path="/about">
                <div className="main-tab">
                  <h2 className={'headline-4'}>***CIS Graph API user interface has been upgraded to a new simpler and self descriptive UI. Checkout the tabs on the top right and have fun!*** </h2>
                  <div className={'main-content responsive-body-3'}>
                    <h2 className={'headline-4'}> What is it!?</h2>
                    <p>CIS Graph API is a one-stop tool for data sharing of multiple security tools. Integrating with tools and data sources in CIS is a difficult and time-consuming process.  In order to interface with a tool, the consumer must find the tool owner, get an API key, understand how the tool works, understand its resource limitations, and implement its API.</p>
                    <p>
                      This is where CIS Graph API comes into play. GraphQL is a schema-driven API model that allows those querying it to specify exactly what data they want back; nothing more, nothing less.  Being schema-driven, the API is self-documenting, and the GraphiQL Explorer interface will even allow a user to explore the schema as they build out the query they're looking for in their project.
                    </p>
                  </div>
                  <div className={'main-content responsive-body-3'}>
                    <h2 className={'headline-4'}> What are Graph API queries?</h2>
                    <p>GraphQL is a query language for the Graph API. To know more about it checkout the official documentation - <a href="https://graphql.org/">https://graphql.org/</a></p>
                    <p>But before that checkout the example queries in the <b>Examples Tab</b> in <b>Explore API</b> section.</p>
                  </div>
                  <div className={'main-content responsive-body-3'}>
                    <h2 className={'headline-4'}> How to use the API with your apps?</h2>
                    <p>The Authorization Token tab on the top right corner would give you a jwt token which can be used to fetch the data you need.</p>
                  </div>
                  <div className={'main-content responsive-body-3'}>
                    <h2 className={'headline-4'}> Which apps have been integrated already?</h2>
                    <ul>
                      <li>DivvyCloud</li>
                      <li>Rapid7 Security Agents</li>
                      <li>Trend Micro Agents</li>
                      <li>Prisma Cloud</li>
                      <li>AD Roles</li>
                      <li>Network Lists</li>
                      <li>Twistlock Agents</li>
                      <li>Crowdstrike Agents</li>
                    </ul>
                  </div>
                  <div className={'main-content responsive-body-3'}>
                    <h2 className={'headline-4'}>About us</h2>
                    <p>The project CIS Graph API is being updated pretty frequently, the details of which can be found on our confluence page <a href="https://confluence.nike.com/display/SECAUTO/CIS+Graph+API">here</a>.</p>
                    <p>If you are facing any issues with the app, you can reach us at
                      our slack channel - #cis-automation or create a ticket through our intake form <a href="https://confluence.nike.com/display/SAE/Automation+Work+Intake+Form">here</a> or mail us at Lst-Technology.CIS.ESD.AUTOMATION@nike.com.</p>
                  </div>
                </div>
              </Route>
              <Route path="/explore">
                <div className="graphiql-tab">
                  <GraphiQL fetcher={fetcher}
                            editorTheme={'dracula'}
                            headerEditorEnabled={true}
                            shouldPersistHeaders={true}
                            headers={JSON.stringify({
                              "Authorization": "Bearer " + accessToken
                            })}
                            query={currentQuery}
                            variables={currentVariables}
                            toolbar={{
                              "additionalContent": <div>
                                <ListQueries onQuerySelect={handleSelectedQuery}/>
                                {queryParamLength > maxQueryParamLength && (
                                  <span className='query-size-error'>
                                    <Alert className="va-sm-m "
                                           fill="#d43f21"
                                           width="18px" height="18px"
                                           title={"Warning: Due to size of your query, the URL may not be sharable."}
                                    />
                                  </span>
                                )}
                              </div>,
                            }}
                            onEditQuery={(value) => setCurrentQuery(value)}
                            onEditVariables={(value) => setCurrentVariables(value)}
                  /></div>
              </Route>
              <Route path="/token">
                <div className="main-tab">
                  <h2 className={'headline-2'}>How to access the CIS Graph API: {CIS_GRAPH_ENDPOINT}
                    <CopyToClipboard text={CIS_GRAPH_ENDPOINT}
                                     onCopy={() => setUrlCopied(true)}>
                      <IconButton aria-label="copy">
                        <FileCopyIcon/>
                      </IconButton>
                    </CopyToClipboard>
                    <span className={`clipboard-confirm ${urlCopied ? 'copy-shown' : 'copy-hidden'}`}
                          onTransitionEnd={() => setUrlCopied(false)}>Url Copied</span>
                  </h2>
                  <h4 className={'headline-4'}>The following token can be pasted as an 'Authorization' header in your <a
                    href="https://graphql.org/learn/" target="_blank" rel='noreferrer'>GraphQL<LaunchIcon
                    style={{fontSize: 12}}/></a> IDE.
                  </h4>
                  <div className={'token-container'}>
                    <TextField
                      id="outlined-full-width"
                      label="Auth Token"
                      style={{margin: 8}}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      multiline
                      value={accessToken}
                    />
                    <div className={'pl2-sm'}>Token expires on: {tokenExpiration}</div>
                    <div className={'button-container'}>
                      <button onClick={() => handleRefreshToken()} className={'ncss-btn-primary-dark'}>Refresh token
                      </button>
                      <CopyToClipboard text={accessToken}
                                       onCopy={() => setAndDisplayActionMessage("Token copied")}>
                        <button className={'ncss-btn-primary-dark'}>Copy to clipboard</button>
                      </CopyToClipboard>
                      <span className={`clipboard-confirm ${displayActionMessage ? 'copy-shown' : 'copy-hidden'}`}
                            onTransitionEnd={() => setDisplayActionMessage(false)}>{actionMessage}</span>
                    </div>
                    <div className={`refresh-overlay ${isRefreshing ? 'visible' : 'hidden'}`}>
                      <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={100}
                        width={100}
                      />
                    </div>
                  </div>
                </div>
              </Route>
              <Route>
                <Redirect to="/"/>
              </Route>
            </Switch>
          </div>
          <div className={'footer'}>
            <div className={'footer-content'}>
              <span>{`CIS Graph API ${new Date().getFullYear()}`}</span>
            </div>
          </div>
        </div>
      </ApolloProvider>
    )
  } else {
    return (
      <div>
        <p>Not logged in!</p>
      </div>
    )
  }
};

export default App;
