const queries = [
  // Default
  {
    key: "CLOUD_ACCOUNTS_ID_NAME",
    displayLabel: "Basic Cloud Account Query",
    gql: {
      query: `# This tab provides a GraphiQL API IDE for API queries. Try our Examples (see the dropdown above).
#
# Feel free to overwrite this panel and build your own, or start with those examples.
# Note you can link and share queries via the URL in the address bar.

query # Write your query or mutation here
    {
      cloud {
        accounts {
          accountId
          name
        }
      }
    }
`,
      variables: null
    }
  },

  {
    key: "CLOUD_INSTANCES",
    displayLabel: "Cloud Instances",
    gql: {
      query: `
query cloud {
    cloud {
      instances{
        instanceId
        name
        state
        region
        availabilityZone
        launchTime
        platform
        imageId
        keyName
        publicIpAddress
        privateIpAddress
        roleName
        upTime
        image{
          imageId
        } 
      }
    }
  }`,
      variables: null
    }
  },

  {
    key: "INSTANCE STATE QUERY EXAMPLE",
    displayLabel: "Instance State Count Query",
    gql: {
      query: `
query {
  cloud{
    instanceStateCount(cloudType:"AWS"){
      running
      pending
      stopped
      terminated
    }
  }
}`,
      variables: null
    }
  },

  {
    key: "SECURITY AGENTS QUERY EXAMPLE",
    displayLabel: "Security Agents Query",
    gql: {
      query: `
query {

  securityAgents(agentNames:["Rapid7"]){
    name
    hostname
    version
    latestVersion
    status
    host{
      instanceId
    }
    ipAddress
    instanceId
  }
}`,
      variables: null
    }
  },

  {
    key: "RAPID7 SECURITY AGENTS QUERY EXAMPLE",
    displayLabel: "Rapid7 Security Agents Query",
    gql: {
      query: `
query BaseAgents{
  rapid7{
    baseAgent(batchSize:1000){
      agentId
      instanceId
      agentSemanticVersion
      agentStatus
      platform
      description
      hostNames
      vendor
      version
      attributes
      primaryAddress
    }
  }
}`,
      variables: null
    }
  },

  {
    key: "NETWORK LIST QUERY EXAMPLE",
    displayLabel: "Network List Query",
    gql: {
      query: `
query {
  networkLists(listNames:["Route53"]){
    ListId
    IpAddress
    ListName
  }
}`,
      variables: null
    }
  },

  {
    key: "PRISMA CLOUD QUERY EXAMPLE",
    displayLabel: "Prisma Cloud Query",
    gql: {
      query: `
query {
  prismaCloud{
    complianceImagesRegistries{
      registry
      repository
      tag
      complianceCount
      complianceIssues{
        id
      }
      riskFactors
    }
  }
}`,
      variables: null
    }
  },

  {
    key: "TREND AGENT QUERY EXAMPLE",
    displayLabel: "Trend Agent Query",
    gql: {
      query: `
query {
  trendMicroAgents{
    Amazon
    Amazon2
  }
}`,
      variables: null
    }
  },

  {
    key: "CLOUD_FIELD_EXAMPLE_1",
    displayLabel: "Cloud Query Example",
    gql: {
      query: `
query {
  cloud {
      accounts {
        instances {
          instanceId
          securityAgents {
            name
            ipAddress
            version
            hostname
            status
            host {
              instanceId
            }
            latestVersion
          }
          complianceFindings {
            resourceId
            detectionDate
            complianceControl {
              controlId
              name
              description
            }
          }
        }
      }
    }
  }`,
      variables: null
    }
  },
]



export default queries.sort((a, b) => {
  let result = 0  // names must be equal

  if (a.key < b.key) result = -1;
  if (a.key > b.key) result = 1;

  return result;
})
