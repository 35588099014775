import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { BrowserRouter as Router, Route } from 'react-router-dom';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_ISSUER,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin + '/implicit/callback',
  pkce: true,
  tokenManager: {
    expireEarlySeconds: 300
  },
  scopes: ['cis.graph.read', 'openid']
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Security oktaAuth={oktaAuth}>
        <SecureRoute path={"/"} />
        <Route path="/implicit/callback" component={LoginCallback} />
        <App />
      </Security>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
