import React from 'react'
import GraphiQL from 'graphiql'

import '../../App.scss'
import examples from '../../exampleQueries';

export default function ListQueries(props) {
  return (
    <GraphiQL.Menu label='Examples' title='Examples'>
      {
        examples.map(exampleQuery => (
          <GraphiQL.MenuItem label={exampleQuery.displayLabel}
                             title={exampleQuery.key}
                             key={exampleQuery.key}
                             onSelect={() => props.onQuerySelect(exampleQuery.gql)}/>
        ))
      }
    </GraphiQL.Menu>
  )
}
