const lzw = require('lz-string')

const encodeUrl = (obj = {}) => {
  const stringified = JSON.stringify(obj)
  const compressed = lzw.compressToEncodedURIComponent(stringified)
  return compressed
}

const decodeUrl = (compressed = '') => {
  if (compressed) {
    const stringified = lzw.decompressFromEncodedURIComponent(compressed)
    return JSON.parse(stringified)
  } else {
    return {}
  }
}


module.exports = {
  encodeUrl,
  decodeUrl
}