const DEFAULT_QUERY = {
    query: `# This tab provides a GraphiQL API IDE for API queries. Try our Examples (see the dropdown above).
#
# Feel free to overwrite this panel and build your own, or start with those examples.
# Note you can link and share queries via the URL in the address bar.

query cloud {
    cloud {
      accounts {
        accountId
        name
      }
    }
  }`,
  variables: ``
}

export {DEFAULT_QUERY}
